import React from "react"

import Aboutus from "../componets/aboutus"

import Ourteam from "../componets/ourteam"
import Footer from "../componets/footer"
import Navbar1 from "../componets/navbar1"


export const Aboutusv = () => {

    return (
        <>
        <Navbar1/>
        <Aboutus/>
        
        <Ourteam/>
        <Footer/>
        
        </>
    )

}