import React from "react";


import Footer from "../componets/footer";
import Contact from "../componets/contact";
import Navbar1 from "../componets/navbar1";


export const Contactus = () => {

    return (
        <>
        <Navbar1/>
        <Contact/>
        <Footer/>
        
        </>
    )

}