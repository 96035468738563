import React from "react";
import Ourblog from "../componets/ourblog";
import Navbar1 from "../componets/navbar1";
import Footer from "../componets/footer";


export const Blog = () => {

    return (
        <>
        <Navbar1/>
        <Ourblog/>
        <Footer/>
        
        </>
    )

}