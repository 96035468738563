import React from "react";

import Footer from "../componets/footer";
import Ourservice from "../componets/ourservice";
import Industrie from "../componets/industrie";
import Contact from "../componets/contact";
import Navbar1 from "../componets/navbar1";

export const Services = () => {

    return (
        <>
        <Navbar1/>
        <Ourservice/>
        <Industrie/>
        <Contact/>
        <Footer/>
        
        </>
    )

}